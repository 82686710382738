
<div class="card-deck">
  <div class="card animated fadeIn mb-3 card-columns-flex" *ngFor="let portfolio of portfolios">
  <img class="card-img-top" src="{{ portfolio.image }}" alt="{{ portfolio.title }}">
  <div class="card-body">
    <h5 class="card-title">{{ portfolio.title }}</h5>
    <p class="card-text">{{ portfolio.description }}</p>
    <a class="skills" *ngFor="let skill of portfolio.skills"> {{ skill }} </a>
  </div>
</div>
</div>

