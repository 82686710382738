<div class="row mb-4 ml-0 mr-0">
  <div class="card-deck">
    <div class="card animated fadeIn">
      <div class="card-header">
        <b>About me</b>
      </div>
      <div class="card-body">
        <h5 class="card-title">¡I will add value to your company!</h5>
        <p class="card-text">I'm a Web Developer with more than 10 years of experience creating websites.
          I'm a self-taught who continues to learn every day to add more power to my strengths.
          I am passionate with programming and IT.</p>
        <p class="card-text">One of my strengths is that i always deliver my work on time. So, let's do it.</p>
      </div>
    </div>
    <div class="card animated fadeIn">
      <div class="card-header">
        <b>Skills</b>
      </div>
      <div class="card-body">
        <a class="skills">Drupal</a>
        <a class="skills">Drupal Commerce 2</a>
        <a class="skills">Drupal API Backend</a>
        <a class="skills">Angular 10</a>
        <a class="skills">Wordpress</a>
        <a class="skills">Bootstrap</a>
        <a class="skills">PHP</a>
        <a class="skills">Twig</a>
        <a class="skills">JQuery</a>
        <a class="skills">Javascript</a>
        <a class="skills">Mysql</a>
        <a class="skills">Git</a>
        <a class="skills">Firebase Cloud</a>
        <a class="skills">Google Webmaster Tools</a>
        <a class="skills">AWS SES</a>
        <a class="skills">Linux CL</a>
        <a class="skills">Postman</a>
      </div>
    </div>
  </div>
</div>



<div class="card mb-4">
  <div class="card-header">
    <b>Portfolio</b>
  </div>
  <div class="card-body">

    <app-portfolio-card *ngIf="portfolios.length > 0" [portfolios]="portfolios"></app-portfolio-card>


  </div>
</div>
