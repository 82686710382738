<div class="container-fluid mb-5" style="background-color: #DAE3E7;">
  <div class="container">
    <div class="row animated fadeIn">
      <div class="col-12 col-sm-6 col-md-8 mt-3 mb-3">
        <h4>Alan Muñoz</h4>
        <p><b>Senior Web Developer</b><br />
        <small>Santo Domingo, Dominican Republic.</small></p>

          <a href="mailto:alan.luis.munoz@gmail.com">
            <i class="fas fa-envelope"></i> alan.luis.munoz@gmail.com
          </a> <br />
          <a href="https://github.com/alanmunoz25">
            <i class="fab fa-github"></i> Github
          </a> <br />
          <a href="https://www.linkedin.com/in/alan-munoz-707a0310/">
            <i class="fab fa-linkedin"></i> LinkedIn
          </a> <br />
          <button class="btn-info btn mr-2 mt-2 " (click)="englishResume()">
            <i class="fas fa-cloud-download-alt"> </i> English Resume
          </button>
          <button class="btn-info btn mt-2" (click)="spanishResume()">
            <i class="fas fa-cloud-download-alt"> </i> Spanish Resume
          </button>

      </div>
      <div class="col-6 col-md-4">
        <img class="w-75 d-block mx-auto mb-3 mt-3 img-fluid rounded-circle" src="https://media-exp1.licdn.com/dms/image/C4E03AQEmfvex3t3Phw/profile-displayphoto-shrink_800_800/0/1541169868963?e=1617235200&v=beta&t=SCbKXpr4hyyltNsSdaUs2uGTbVlg2q1Z-W5Lz3cvcfA" alt="profile image">
      </div>
    </div>
  </div>
</div>
